<template>
    <div></div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    mounted() {
        this.checkOutPatient()
        this.$router.replace({ name: 'PatientCheckIn' })
    },

    methods: {
        ...mapActions('patient', ['checkOutPatient']),
    },
}
</script>
